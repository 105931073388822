import React, {useState, useEffect} from 'react'
import AppHeader from '../appHeader/appHeader'
import AppMenu from '../appMenu/appMenu'
import AppFooter from '../appFooter/appFooter'
import AppSetting from '../appSetting/appSetting'
import axios from 'axios'

function Bi(){

   const [authenticated, setAuthenticated] = useState()
   const [user, setUser] = useState(null)

   useEffect(() => {
        axios.post(`/api/appointment/get/user`, null, {
            withCredentials: true
          })
            .then((response) => {

              if (response.data.auth === true) {
                setAuthenticated(true);
                setUser(response.data.user);
              } else {
                window.location = 'login';
              }
            })
            .catch(() => {
              window.location = 'login';
            });
  }, [authenticated]);


    if (!authenticated) {
      return <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
    }}>
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    </div>;
  }

  return(
    <>
	  <AppHeader />
          <AppMenu response={user}/>
	  <div className="content-wrapper" style={{minHeight: 675}}>
	  <div className="content">
	  <iframe width={'100%'} height={675} src="https://lookerstudio.google.com/embed/reporting/6d96601c-ae21-4aa0-967f-b552521a02a7/page/XN8cD" frameBorder={0} style={{border: 0}} allowFullScreen />
	  </div>
	  </div>
	  <AppFooter />
          <AppSetting />
	  </> 
  )
}

export default Bi;
