import React, {useState, useEffect} from 'react'
import AppHeader from '../appHeader/appHeader'
import AppMenu from '../appMenu/appMenu'
import AppFooter from '../appFooter/appFooter'
import AppSetting from '../appSetting/appSetting'
import DataTable from '../dataTable/DataTable'
import axios from 'axios'
import { format } from 'date-fns'
import { faSleigh } from '@fortawesome/free-solid-svg-icons'

function Appointment(){

    // Obter a data do localStorage ou usar a data atual como padrão
    const savedDate = localStorage.getItem('selectedDate');
    const savedCore = localStorage.getItem('selectedCore');
    const savedExpand1 = JSON.parse(localStorage.getItem('expand1')) || false;
    const savedExpand2 = JSON.parse(localStorage.getItem('expand2')) || false;
    const savedExpand3 = JSON.parse(localStorage.getItem('expand3')) || false;


    const defaultDate = savedDate ? new Date(savedDate) : new Date();
    const defaultCore = savedCore ? savedCore : 'Empresarial';
    const defaultExpand1 = savedExpand1 ? savedExpand1: false;
    const defaultExpand2 = savedExpand2 ? savedExpand2: false;
    const defaultExpand3 = savedExpand3 ? savedExpand3: false;


    // Estado para armazenar a data selecionada
    const [selectedDate, setSelectedDate] = useState(defaultDate);
    const [nucleo, setNucleo] = useState(defaultCore);
    const [showModal, setShowModal] = useState(false);
    const [showModalObstaculo, setShowModalObstaculo] = useState(false);
    const [user, setUser] = useState(null)
    const [icon1, setIcon1] = useState(defaultExpand1)
    const [icon2, setIcon2] = useState(defaultExpand2)
    const [icon3, setIcon3] = useState(defaultExpand3)

    const yesterday = new Date();
    yesterday.setDate(selectedDate.getDate()-1)
    if(yesterday.getDay()===0){
    yesterday.setDate(yesterday.getDate()-2)
    }

    const [appointmentBusiness, setAppointmentBusiness] = useState({prazosAn: null,
                                                    pasta: null,
                                                    cliente: null,
                                                    executante: null,
                                                    nucleo: null,
                                                    status: null,
                                                    descricao: null,
                                                    motivo: null,
                                                    observacoes: null,
                                                    conclusao: null,
                                                    obstaculo: null,
                                                    motivoObstaculo: null,
                                                    registro: null});

    const [appointmentBusinessYesterday, setAppointmentBusinessYesterday] = useState({prazosAn: null,
                                                      pasta: null,
                                                      cliente: null,
                                                      executante: null,
                                                      nucleo: null,
                                                      status: null,
                                                      descricao: null,
                                                      motivo: null,
                                                      observacoes: null,
                                                      conclusao: null,
                                                      obstaculo: null,
                                                      motivoObstaculo: null,
                                                      registro: null});

    const [appointmentController, setAppointmentController] = useState({prazosAn: null,
                                                      pasta: null,
                                                      cliente: null,
                                                      executante: null,
                                                      nucleo: null,
                                                      status: null,
                                                      descricao: null,
                                                      motivo: null,
                                                      observacoes: null,
                                                      conclusao: null,
                                                      obstaculo: null,
                                                      motivoObstaculo: null,
                                                      registro: null});

    const [appointmentControllerYesterday, setAppointmentControllerYesterday] = useState({prazosAn: null,
                                                        pasta: null,
                                                        cliente: null,
                                                        executante: null,
                                                        nucleo: null,
                                                        status: null,
                                                        descricao: null,
                                                        motivo: null,
                                                        observacoes: null,
                                                        conclusao: null,
                                                        obstaculo: null,
                                                        motivoObstaculo: null,
                                                        registro: null});

    const [appointmentLabor, setAppointmentLabor] = useState({prazosAn: null,
                                                      pasta: null,
                                                      cliente: null,
                                                      executante: null,
                                                      nucleo: null,
                                                      status: null,
                                                      descricao: null,
                                                      motivo: null,
                                                      observacoes: null,
                                                      conclusao: null,
                                                      obstaculo: null,
                                                      motivoObstaculo: null,
                                                      registro: null})

    const [appointmentLaborYesterday, setAppointmentLaborYesterday] = useState({prazosAn: null,
                                                        pasta: null,
                                                        cliente: null,
                                                        executante: null,
                                                        nucleo: null,
                                                        status: null,
                                                        descricao: null,
                                                        motivo: null,
                                                        observacoes: null,
                                                        conclusao: null,
                                                        obstaculo: null,
                                                        motivoObstaculo: null,
                                                        registro: null}) 
    
    const [lateBusiness, setLateBusiness] = useState({prazosAn: null,
                                      pasta: null,
                                      cliente: null,
                                      executante: null,
                                      nucleo: null,
                                      status: null,
                                      descricao: null,
                                      motivo: null,
                                      observacoes: null,
                                      conclusao: null,
                                      obstaculo: null,
                                      motivoObstaculo: null,
                                      registro: null})

    const [lateLabor, setLateLabor] = useState({prazosAn: null,
                                        pasta: null,
                                        cliente: null,
                                        executante: null,
                                        nucleo: null,
                                        status: null,
                                        descricao: null,
                                        motivo: null,
                                        observacoes: null,
                                        conclusao: null,
                                        obstaculo: null,
                                        motivoObstaculo: null,
                                        registro: null})

    const [lateController, setLateController] = useState({prazosAn: null,
                                          pasta: null,
                                          cliente: null,
                                          executante: null,
                                          nucleo: null,
                                          status: null,
                                          descricao: null,
                                          motivo: null,
                                          observacoes: null,
                                          conclusao: null,
                                          obstaculo: null,
                                          motivoObstaculo: null,
                                          registro: null})

    const [authenticated, setAuthenticated] = useState()

    const [modal, setModal] = useState({prazosAn: null,
                                        pasta: null,
                                        cliente: null,
                                        executante: null,
                                        nucleo: null,
                                        status: null,
                                        descricao: null,
                                        motivo: null,
                                        observacoes: null,
                                        conclusao: null,
                                        obstaculo: null,
                                        motivoObstaculo: null,
                                        registro: null}) 

    useEffect(() => {
      // Atualizar o localStorage quando a data selecionada mudar
      localStorage.setItem('selectedDate', selectedDate);
      localStorage.setItem('selectedCore', nucleo);

    }, [selectedDate, nucleo]);
    
    useEffect(() => {

        axios.post(`/api/appointment/get/`, {selectedDate}, {
          withCredentials: true,
        })
            .then((response) => {
              console.log('then')
              if (response.data.auth === true) {
                
                setAuthenticated(true);
                setAppointmentBusiness(response.data.allAppointmentBusiness);
                setAppointmentLabor(response.data.allAppointmentLabor);
                setAppointmentBusinessYesterday(response.data.allAppointmentBusinessYesterday);
                setAppointmentLaborYesterday(response.data.allAppointmentLaborYesterday);
                setAppointmentControllerYesterday(response.data.allAppointmentControllerYesterday);
                setAppointmentController(response.data.allAppointmentController);
                setLateBusiness(response.data.overdueAppointmentsBusiness)
                setLateLabor(response.data.overdueAppointmentsLabor)
                setLateController(response.data.overdueAppointmentsController)
                setUser(response.data.user)
              } else {
                window.location = 'login';
              }
            })
            .catch(() => {
              window.location = 'login';
            });
  }, [authenticated]);


    if (!authenticated) {
      return <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
    }}>
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
    </div>;
  }


  async function expanded1(){
    if(icon1){
      setIcon1(false)
      localStorage.setItem('expand1', false)
    }
    else{
      setIcon1(true)
      localStorage.setItem('expand1', true)
    }
  }

  async function expanded2(){
    if(icon2){
      setIcon2(false)
      localStorage.setItem('expand2', false)
    }
    else{
      setIcon2(true)
      localStorage.setItem('expand2', true)
    }
  }

  async function expanded3(){

    if(icon3){
      setIcon3(false)
      localStorage.setItem('expand3', false)
    }
    else{
      setIcon3(true)
      localStorage.setItem('expand3', true)
    }
  }

  const handleDateChange = async (e) => {
    if(e.target.value == ''){
      setSelectedDate('');
    }else{
      const [year, month, day] = e.target.value.split('-');
      const newDate = new Date(year, month - 1, day);  // JavaScript conta meses a partir de 0
      setSelectedDate(newDate);
      const response = await axios.post(`/api/appointment/get/`, {selectedDate: newDate}, {
        withCredentials: true,
      })
      setAppointmentBusiness(response.data.allAppointmentBusiness)
      setAppointmentBusinessYesterday(response.data.allAppointmentBusinessYesterday)
      setAppointmentLabor(response.data.allAppointmentLabor)
      setAppointmentLaborYesterday(response.data.allAppointmentLaborYesterday)
      setAppointmentController(response.data.allAppointmentController)
      setAppointmentControllerYesterday(response.data.allAppointmentControllerYesterday)
    }
  };

    const handleChange = (event) => {
      if (event.target.value === 'openModal') {
        setShowModal(true);
      }
      
    };

    const handleChangeObstacle = (event) => {
      if (event.target.value === 'openModal') {
        setShowModalObstaculo(true);
      }
      
    };

    /*function allInputChange(event) {
      const { name, value } = event.target;
      console.log('-------------------')
      console.log(name)
      setModal(prevState => ({
        ...prevState,
        [name]: value
      }));
}*/

    function selectCore(){
      if(nucleo=='Empresarial'){
        return <select className='form-control' onChange={handleChangeCore}> 
                                <option>Trabalhista</option>
                                <option selected>Empresarial</option>
                                <option>Controladoria</option>

               </select>
      }
      else if(nucleo=='Controladoria'){
        return <select className='form-control' onChange={handleChangeCore}> 
                                <option>Trabalhista</option>
                                <option>Empresarial</option>
                                <option selected>Controladoria</option>
               </select>
      }
      else{
        return <select className='form-control' onChange={handleChangeCore}> 
                                <option selected>Trabalhista</option>
                                <option>Empresarial</option>
                                <option>Controladoria</option>

                              </select>
      }
    }

    const handleChangeCore = (event) =>{
      console.log('handle change core:')
      console.log(event.target.value)
      setNucleo(event.target.value)
    }

    function Modal({ show, onClose }) {
        const [localObservacoes, setLocalObservacoes] = useState(modal.observacoes || '');

        const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'observacoes') {
          setLocalObservacoes(value);
        } 
        else {
          setModal({ ...modal, [name]: value });
        }
  };

        return (
          <div className={`modal ${show ? 'show' : ''}`} tabIndex="-1" role="dialog" style={show ? {display: 'block'} : {}}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Pasta {modal.pasta}</h5>
                  <button type="button" className="close" onClick={onClose}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <form method='POST' action={`/api/appointment/update/${modal.id}`}>
                <div className="modal-body">
                    <div className='row'>
                        <label>Qual o motivo da não conclusão:</label>
                    </div>
                    <div className='row'>
                        <select className='form-control' name='motivo'>
                            {modal.motivo != null ? <option selected>{modal.motivo}</option>: <option selected></option>}
                            <option>Sobrecarga de Trabalho</option>
                            <option>Aguardando validação do cliente</option>
                            <option>Aguardando posicionamento</option>
                            <option>Aguardando comprovante de pagamento</option>
                            <option>Aguardando documentação do cliente</option>
                            <option>Entrada de trabalho extraordinário</option>
                        </select>
                    </div>
                    <div className='row'>
                      <label>
                        Observações:
                      </label>
                    </div>
                    <div className='row'>
                       <textarea
                       className='form-control'
                       value={localObservacoes}
                       name='observacoes'
                       onChange={handleInputChange}/>
                    </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={onClose}>Fechar</button>
                  <button type="submit" className="btn btn-primary">Salvar</button>
                </div>
                </form>
              </div>
            </div>
          </div>
        );
      }

    function ModalObstacle({ show, onClose }) {
        const [localObservacoes, setLocalObservacoes] = useState(modal.obstaculo || '');

        const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'obstaculo') {
          setLocalObservacoes(value);
        } 
        else {
          setModal({ ...modal, [name]: value });
        }
  };

        return (
          <div className={`modal ${show ? 'show' : ''}`} tabIndex="-1" role="dialog" style={show ? {display: 'block'} : {}}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Pasta {modal.pasta}</h5>
                  <button type="button" className="close" onClick={onClose}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <form method='POST' action={`/api/appointment/obstacle/${modal.id}`}>
                <div className="modal-body">
                    <div className='row'>
                        <label>Qual o motivo da não conclusão:</label>
                    </div>
                    <div className='row'>
                        <select className='form-control' name='motivoObstaculo'>
                            {modal.motivoObstaculo != null ? <option selected>{modal.motivoObstaculo}</option>: <option selected></option>}
                            <option>Sobrecarga de Trabalho</option>
                            <option>Aguardando validação do cliente</option>
                            <option>Aguardando posicionamento</option>
                            <option>Aguardando comprovante de pagamento</option>
                            <option>Aguardando documentação do cliente</option>
                            <option>Entrada de trabalho extraordinário</option>
                        </select>
                    </div>
                    <div className='row'>
                      <label>
                        Observações:
                      </label>
                    </div>
                    <div className='row'>
                       <textarea
                       className='form-control'
                       value={localObservacoes}
                       name='obstaculo'
                       onChange={handleInputChange}/>
                    </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={onClose}>Fechar</button>
                  <button type="submit" className="btn btn-primary">Salvar</button>
                </div>
                </form>
              </div>
            </div>
          </div>
        );
      }
    
    function edit(n, event){
      const selectElement = event.target;
      const selectedOption = selectElement.options[selectElement.selectedIndex];
      const selectedText = selectedOption.text;

      axios.get(`/api/appointment/get/one/${n}`, {
        withCredentials: true,
      }).then((res)=>{
        setModal(res.data.appointment)
      })
      axios.put(`/api/appointment/concluded/${n}`, {concluded: selectedText}, {
        withCredentials: true,
      })

    }

    function editObstacle(n, event){
      const selectElement = event.target;
      const selectedOption = selectElement.options[selectElement.selectedIndex];
      const selectedText = selectedOption.text;

      axios.get(`/api/appointment/get/one/${n}`, {
        withCredentials: true,
      }).then((res)=>{
        setModal(res.data.appointment)
      })
      axios.put(`/api/appointment/update/obstacle/${n}`, {concluded: selectedText}, {
        withCredentials: true,
      })

    }   

   //dados presentes nas linhas
   //a coluna id é obrigatória
   /*const data = [
    { id: 1, pasta: "0003560", descricao: <p style={{width: '50%'}}>Por gentileza confeccionar um contrato de monitoramento de lavoura conforme negociação em anexos</p>, executante: "Carlos Eduardo Ribeiro Plugliezi", status: "Pendente", concluido: <select className='form-control' onChange={handleChange}>
     <option value="default">Selecione uma opção</option>
     <option value="openModal">Não</option>
     <option value="doNothing">Sim</option>
</select>},
    { id: 2, pasta: "0003470", descricao: <p style={{width: '50%'}}>Por gentileza confeccionar um contrato de monitoramento de lavoura conforme negociação em anexos</p>, executante: "Pedro", status: "Pendente", concluido:<select className='form-control'>
     <option value="default">Selecione uma opção</option>
     <option value="openModal">Não</option>
     <option value="doNothing">Sim</option>
</select>},
    { id: 3, pasta: "0003242", descricao: <p style={{width: '50%'}}>Por gentileza confeccionar um contrato de monitoramento de lavoura conforme negociação em anexos</p>, executante: "Viviane", status: "Pendente", concluido: <select className='form-control'>
     <option value="default">Selecione uma opção</option>
     <option value="openModal">Não</option>
     <option value="doNothing">Sim</option>
</select>},
  ];*/
  let dataHoje =[]
  let dataOntem =[]
  let dateLate = []
  if(nucleo=='Empresarial'){
    dataHoje = appointmentBusiness
    dataOntem = appointmentBusinessYesterday
    dateLate = lateBusiness
  }
  else if(nucleo=='Trabalhista'){
    dataHoje = appointmentLabor
    dataOntem = appointmentLaborYesterday
    dateLate = lateLabor
  } 
  else{
    dataHoje = appointmentController
    dataOntem = appointmentControllerYesterday
    dateLate = lateController
  }
  /*const dataHoje = nucleo=='Empresarial'? appointmentBusiness : appointmentLabor
  const dataOntem = nucleo=='Empresarial'? appointmentBusinessYesterday : appointmentLaborYesterday*/
  
  dataOntem.map((resp, index)=>(
    resp.concluido = <select className='form-control' onChange={(e) => { handleChangeObstacle(e); edit(resp.id, e); }}>
    {resp.conclusao == 'Selecione uma opção' ?<option value="default" selected>Selecione uma opção</option>: <option value="default">Selecione uma opção</option>}
    {resp.conclusao == 'Sim' ?<option value="doNothing"selected>Sim</option> : <option value="doNothing">Sim</option>}
    {resp.conclusao == 'Não' ?<option value="openModal" selected>Não</option> : <option value="openModal">Não</option>}
</select>
  ))

  dataHoje.map((resp, index)=>(
    resp.concluido = <select className='form-control' onChange={(e) => { handleChange(e); editObstacle(resp.id, e); }}>
    {resp.registro == 'Selecione uma opção' ?<option value="default" selected>Selecione uma opção</option>: <option value="default">Selecione uma opção</option>}
    {resp.registro == 'Sim' ?<option value="openModal" selected>Sim</option> : <option value="openModal">Sim</option>}
    {resp.registro == 'Não' ?<option value="doNothing" selected>Não</option> : <option value="doNothing">Não</option>}
</select>
  ))
  
  /*dateLate.map((resp, index)=>(
    resp.concluido = <select className='form-control' onChange={(e) => { handleChange(e); edit(resp.id, e); }}>
    {resp.conclusao == 'Selecione uma opção' ?<option value="default" selected>Selecione uma opção</option>: <option value="default">Selecione uma opção</option>}
    {resp.conclusao == 'Sim' ?<option value="openModal" selected>Sim</option> : <option value="openModal">Sim</option>}
    {resp.conclusao == 'Não' ?<option value="doNothing" selected>Não</option> : <option value="doNothing">Não</option>}
</select>
  ))*/

  // colunas normais
  // title é obrigatório
  const columns = [
    { title: "#", key: "id" },
    { title: "Cliente", key: "cliente" },
    { title: "Prazo AN", key: "prazosAn" },
    { title: "Pasta", key: "pasta" },
    { title: "Descrição", key: "descricao" },
    { title: "Executante", key: "executante" },
    { title: "Status", key: "status" },
    { title: "Há obstaculo para conclusão?", key: "concluido" }
  ];

  const columnsYesterday = [
    { title: "#", key: "id" },
    { title: "Cliente", key: "cliente" },
    { title: "Prazo AN", key: "prazosAn" },
    { title: "Pasta", key: "pasta" },
    { title: "Descrição", key: "descricao" },
    { title: "Executante", key: "executante" },
    { title: "Status", key: "status" },
    { title: "Concluido?", key: "concluido" }
  ]

  const columnsLate = [
    { title: "#", key: "id" },
    { title: "Cliente", key: "cliente" },
    { title: "Prazo AN", key: "prazosAn" },
    { title: "Pasta", key: "pasta" },
    { title: "Descrição", key: "descricao" },
    { title: "Executante", key: "executante" },
    { title: "Status", key: "status" }
  ]

  //colunas para mobile
  //a coluna id é obrigatória
  const mobileColumns = [
    { title: "#", key: "id" },
    { title: "Details", key: "details" },
  ];

    return(
        <>
         <AppHeader />
         <AppMenu response={user}/>
         <div className="content-wrapper" style={{minHeight: 675}}>
      <div className="content">
        <div className="container-fluid">
          <div className="row mb-2 center-element">
            <div className="col-12" style={{marginTop: "15px"}}>
            
            <div class="card">
                    <div class="card-header center-element bg-dark">
                        <h3 class="card-title">Compromissos</h3>
                    </div>
                    <div class="card-header">
        
                        <div className='row'>
                            <div className='col-3'>
                              <label style={{marginRight:5}}>
                                Núcleo:
                              </label>
                              {selectCore()}
                            </div>
                            <div className='col-3'>
                              <label style={{marginRight:5}}>
                                Dia:
                              </label>
                              <input className='form-control' type='date' value={selectedDate=='' ? selectedDate :format(selectedDate, 'yyyy-MM-dd')}
        onChange={handleDateChange} />
                            </div>
                        </div>
                        
                    </div>
                    {/* <!-- /.card-header --> */}
                    <div className="card-body">
                      <div className="card-header bg-dark">
                        <h3 className="card-title">{format(yesterday, 'dd/MM/yyyy')}</h3>
                        <div className="card-tools">
                        <button className="btn btn-dark" type="button" onClick={expanded1}>
                        {icon1? <i className="fas fa-minus"></i>: <i className="fas fa-plus"></i>}
                        </button>                    
                        </div>
                      </div>
                      <div id='table1' className={`collapse ${icon1 ? 'show' : ''}`}>
                        <DataTable data={dataOntem} 
                                                     columns={columnsYesterday} 
                                                     mobileColumns={mobileColumns} />
                      </div>
                
                        <div className="card-header bg-dark">
                          <h3 className="card-title">{format(selectedDate, 'dd/MM/yyyy')}</h3>
                          <div className="card-tools">
                        <button className="btn btn-dark" type="button" onClick={expanded2}>
                        {icon2? <i className="fas fa-minus"></i>: <i className="fas fa-plus"></i>}
                        </button>                    
                        </div>
                        </div>
                        <div id='table2' className={`collapse ${icon2 ? 'show' : ''}`}>
                        <DataTable data={dataHoje} 
                                                     columns={columns} 
                                                     mobileColumns={mobileColumns} />
                        </div>

                        <div className="card-header bg-dark">
                          <h3 className="card-title">Atrasados</h3>
                          <div className="card-tools">
                        <button className="btn btn-dark" type="button" onClick={expanded3}>
                  
                          {icon3? <i className="fas fa-minus"></i>: <i className="fas fa-plus"></i>}
                        </button>                    
                        </div>
                        </div>
                        <div id='table3' className={`collapse ${icon3 ? 'show' : ''}`}>
                        <DataTable data={dateLate} 
                                                     columns={columnsLate} 
                                                     mobileColumns={mobileColumns} />
                        </div>
                    </div>
                    {/* <!-- /.card-body --> */}
                    </div>

            {showModal && <Modal show={showModal} onClose={() => setShowModal(false)} />}
	    {showModalObstaculo && <ModalObstacle show={showModalObstaculo} onClose={() => setShowModalObstaculo(false)} />}
            </div>
          </div>
        </div>
      </div>
    </div>
         <AppFooter />
         <AppSetting />
        </>
    )
}

export default Appointment; 
