import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './login/login';
import Appointment from './appointment/Appointment'
import NoMatch from './noMatch/noMatch'
import Bi from './bi/bi'

function App() {

  return (
    <div className="wrapper">
      <BrowserRouter>
        <Routes>
          <Route path='/appointment/login' exact element={<Login />}/>
          <Route path='/appointment' element={<Appointment />}/>
	  <Route path='/appointment/bi' element={<Bi />}/>
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
 
export default App;
