import React, { useState, useEffect } from "react";

function DataTable({ data, columns, mobileColumns }) {
  //determina se a tela é grande ou não
  const [isMobile, setIsMobile] = useState(false);
  
  const [expandedRows, setExpandedRows] = useState(new Set());


  const handleResize = () => {
    const screenWidth = window.innerWidth;
    const mobileWidth = 768;
    setIsMobile(screenWidth < mobileWidth);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderColumns = isMobile ? mobileColumns : columns;

  const handleDetailsClick = (row) => {
    const updatedExpandedRows = new Set(expandedRows);
    if (expandedRows.has(row.id)) {
      updatedExpandedRows.delete(row.id);
    } else {
      updatedExpandedRows.add(row.id);
    }
    setExpandedRows(updatedExpandedRows);
  };

  return (
      <table className="table">
        <thead>
          <tr>
            {renderColumns.map((column) => (
              <th key={column.key}>{column.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <React.Fragment key={row.id}>
              <tr>
                {renderColumns.map((column) => (
                  <td key={column.key}>
                    {column.key === "details" ? (
                      <button
                        className="btn btn-outline-primary"
                        type="button"
                        onClick={() => handleDetailsClick(row)}
                      >
                        {expandedRows.has(row.id) ? (
                        <i className="fas fa-minus"></i>
                      ) : (
                        <i className="fas fa-plus"></i>
                      )}{" "}
                        Details
                      </button>
                    ) : (
                      row[column.key]
                    )}
                  </td>
                ))}
              </tr>
              {isMobile && expandedRows.has(row.id) && (
                 columns.map((column, index) => (
                  index == 0 ? null : (
                  <tr>
                    <td colSpan={mobileColumns.length}><b>{column.title}:</b> {row[column.key]}</td>
                  </tr>)
                 ))
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
  );
}

export default DataTable;
