import React from 'react';

function AppMenu({response}) {
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      {/* <!-- Brand Logo --> */}
      <a href="/" className="brand-link">
        <img src="/dist/img/an_elementos-09.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }}/>
        <span className="brand-text font-weight-light">Administrador AN</span>
      </a>

      {/* <!-- Sidebar --> */}
      <div className="sidebar">
        {/* <!-- Sidebar user (optional) --> */}
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image" id="foto-perfil">
            <img src={`/dist/img/${response.dir}`} className="img-circle elevation-2" alt="User Image"/>
          </div>
          <div className="info" id="nome-perfil">
            <a href="#" className="d-block">{response.nome}</a>
          </div>
        </div>
         <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
           
	    <li className="nav-item">
              <a href="/appointment" className="nav-link">
                <i className="nav-icon fas fa-copy text-white" />
                <p className="text-white">Compromissos</p>
               </a>
            </li>
	    <li className="nav-item">
              <a href="/appointment/bi" className="nav-link">
                <i className="nav-icon fas fa-chart-pie text-white" />
                <p className="text-white">Relatórios</p>
              </a>
            </li>
                        
          </ul>
        </nav>
        {/* <!-- /.sidebar-menu --> */}
      </div>
           {/* <!-- /.sidebar --> */}
    </aside>
  );
}

export default AppMenu;
