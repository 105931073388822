

function AppHeader(){
    return (
      <nav class="main-header navbar navbar-expand navbar-white navbar-light">
      {/* <!-- Left navbar links --> */}
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
        </li>
      </ul>
  
      {/* <!-- Right navbar links --> */}
      <ul class="navbar-nav ml-auto">
        
        {/* <!-- Notifications Dropdown Menu -->   */}
        <li class="nav-item dropdown">
          <a  class="nav-link" href="/api/appointment/logout">
              <button id="logout-button" class="btn btn-block btn-outline-dark">Sair</button>
          </a>
        </li>

      </ul> 
    </nav>

    )
}

export default AppHeader;
