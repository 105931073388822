function AppFooter(){
    return (
      <footer class="main-footer">
        <div class="float-right d-none d-sm-block">
        </div>
        <strong>Desenvolvido por AN Tech.</strong>
      </footer>

    )
}

export default AppFooter